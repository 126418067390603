import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import {
  FileDoneOutlined,
  UserAddOutlined,
  ShareAltOutlined,
  LaptopOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  LogoutOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../../redux/actions/auth.actions";
import "./Sidebar.css";

// Mock permissions API response
const fetchRolePermissions = async (role) => {
  // Simulating a permissions API call based on role
  const mockPermissions = {
    SA: {
      dashboard: { view: true, create: true, edit: true, delete: true },
      talents: { view: false, create: false, edit: false, delete: false },
      jobs: { view: true, create: true, edit: true, delete: true },
      transaction: { view: true, create: true, edit: true, delete: true },
      settings: { view: true, create: true, edit: true, delete: true },
      support: { view: true, create: true, edit: true, delete: true },
      referrals: { view: true, create: true, edit: true, delete: true },
    },
    A: {
      dashboard: { view: true, create: true, edit: true, delete: true },
      talents: { view: true, create: false, edit: false, delete: false },
      jobs: { view: false, create: true, edit: true, delete: true },
      // referrals: { view: true, create: true, edit: true, delete: true },
      merchantTransaction: {
        view: true,
        create: true,
        edit: false,
        delete: false,
      },
      merchantList: { view: true, create: true, edit: true, delete: true },
      settings: { view: true, create: true, edit: true, delete: true },
      support: { view: false, create: true, edit: true, delete: true },
    },
    M: {
      dashboard: { view: true, create: false, edit: false, delete: false },
      jobs: { view: true, create: false, edit: true, delete: false },
      // referrals: { view: true, create: true, edit: true, delete: true },
      settings: { view: true, create: false, edit: true, delete: false },
    },
    MP: {
      // merchantList: { view: true, create: true, edit: true, delete: true },
      dashboard: { view: true, create: false, edit: false, delete: false },
      talents: { view: true, create: false, edit: true, delete: false },
      merchantTransaction: {
        view: true,
        create: true,
        edit: false,
        delete: false,
      },
      // referrals: { view: true, create: true, edit: true, delete: true },
      settings: { view: true, create: false, edit: true, delete: false },
    },
    U: {
      dashboard: { view: true, create: false, edit: false, delete: false },
      resume: { view: true, create: false, edit: true, delete: false },
      settings: { view: true, create: false, edit: true, delete: false },
      jobs: { view: true, create: true, edit: true, delete: false },
      support: { view: true, create: true, edit: false, delete: false },
      referrals: { view: true, create: true, edit: true, delete: true },
      transaction: { view: true, create: true, edit: false, delete: false },
    },
  };

  return mockPermissions[role] || {};
};

function Sidebar({ collapsed, onCollapse }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [openKeys, setOpenKeys] = useState(["resume"]);

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
    if (userRole) {
      fetchRolePermissions(userRole).then((perms) => {
        setPermissions(perms);
      });
    }
  }, []);

  const handleLogout = async () => {
    localStorage.clear();
    await dispatch(signOut());
    navigate("/signin");
  };

  const hasPermission = (menuKey, action = "view") => {
    const permitted = permissions[menuKey]?.[action] || false;
    return permitted;
  };

  const advancedMenuItems = [
    {
      label: "Dashboard",
      key: "dashboard",
      icon: <HomeOutlined />,
      permissionKey: "dashboard",
    },
    {
      label: "Resume",
      key: "resume",
      icon: <UsergroupAddOutlined />,
      children: [
        { label: "Update Resume", key: "profile", permissionKey: "resume" },
        {
          label: "Templates",
          key: "resume-templates",
          permissionKey: "resume",
        },
      ],
      permissionKey: "resume",
    },
    {
      label: "Resume",
      key: "talents",
      icon: <UsergroupAddOutlined />,
      children: [
        { label: "New Resume", key: "new-talent", permissionKey: "talents" },
        { label: "List", key: "talent-list", permissionKey: "talents" },
      ],
      permissionKey: "talents",
    },
    {
      label: "Jobs",
      key: "jobs",
      icon: <LaptopOutlined />,
      children: [
        {
          label: "Applied Job List",
          key: "jobs-applied",
          permissionKey: "jobs",
        },
        
      ],
      permissionKey: "jobs",
    },
    {
      label: "Transaction",
      key: "transaction",
      icon: <DollarOutlined />,
      children: [
        {
          label: "Payment History",
          key: "payment-history",
          permissionKey: "transaction",
        },
        { label: "Pricing", key: "pricing", permissionKey: "transaction" },
      ],
      permissionKey: "transaction",
    },
    {
      label: "Transaction",
      key: "merchantTransaction",
      icon: <DollarOutlined />,
      children: [
        {
          label: "Payment History",
          key: "merchant-payment-history",
          permissionKey: "merchantTransaction",
        },
        {
          label: "Pricing",
          key: "merchant-pricing-card",
          permissionKey: "merchantTransaction",
        },
      ],
      permissionKey: "merchantTransaction",
    },
    {
      label: "Referrals",
      key: "referrals",
      icon: <ShareAltOutlined />,
      permissionKey: "referrals",
    },
    {
      label: "Merchant List",
      key: "merchant-list",
      icon: <UserAddOutlined />,
      permissionKey: "merchantList",
    },
    {
      label: "Settings",
      key: "settings",
      icon: <SettingOutlined />,
      permissionKey: "settings",
    },
    {
      label: "Support",
      key: "support",
      icon: <QuestionCircleOutlined />,
      permissionKey: "support",
    },
  ];

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (["resume", "jobs", "transaction"].includes(latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  const renderMenuItems = (menuItems) => {
    return menuItems.map((item) => {
      if (item.children) {
        const childItems = item.children
          // .filter(
          //   (child) =>
          //     !(role === "A" && child.key === "new-talent") &&
          //     !(role === "A" && child.key === "merchant-pricing-card")
          // ) 
          .map((child) => {
            if (!hasPermission(child.permissionKey)) return null;
            return (
              <Menu.Item key={child.key}>
                <Link to={`/${child.key}`}>{child.label}</Link>
              </Menu.Item>
            );
          })
          .filter((child) => child !== null);

        // Only render the submenu if there are permitted child items
        if (childItems.length > 0) {
          return (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
              {childItems}
            </Menu.SubMenu>
          );
        }
        return null;
      }

      if (hasPermission(item.permissionKey)) {
        return (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link
              to={`/${item.key}`}
              onClick={item.key === "logout" ? handleLogout : null}
            >
              {item.label}
            </Link>
          </Menu.Item>
        );
      }
      return null;
    });
  };

  // Adding logout as a persistent option at the bottom
  const renderLogout = () => (
    <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
      Logout
    </Menu.Item>
  );

  // Get the key of the currently active route
  const activeRouteKey = location.pathname.split("/")[1];

  return (
    <Menu
      theme="dark"
      mode="inline"
      inlineCollapsed={collapsed}
      defaultOpenKeys={["talents"]}
      selectedKeys={[activeRouteKey]}
      openKeys={openKeys}
      onOpenChange={handleOpenChange}
      onCollapse={onCollapse}
      className="sidebar-menu"
    >
      {renderMenuItems(advancedMenuItems)}
      {renderLogout()}
    </Menu>
  );
}

export default Sidebar;
