import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../utils/helper";
import api from "../../utils/api";

export const getTalentsLsit = createAsyncThunk(
  "marchant/talentList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/merchant/talent-list/${data}`);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

export const newTalent = createAsyncThunk(
  "marchant/newTalent",
  async (data, { rejectWithValue }) => {
    try { 
      const response = await api.post(`${BASE_URL}/merchant/new-talent`, data);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

export const updateTalent = createAsyncThunk(
  "marchant/updateTalent",
  async (data, { rejectWithValue }) => {
    try { 
      const response = await api.put(`${BASE_URL}/merchant/update-talent`, data);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

export const emailVerification = createAsyncThunk(
  "marchant/emailVerification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(`${BASE_URL}/merchant/email-verification`, data);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

export const getTalentInfo = createAsyncThunk( 
  "marchant/talentInfo",
  async (data, { rejectWithValue }) => {
    try {
        const id = data;
      const response = await api.get(`${BASE_URL}/merchant/talent/${id}`);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

export const talentCount = createAsyncThunk( 
  "marchant/talentCount",
  async (data, { rejectWithValue }) => {
    try {
        const id = data;
      const response = await api.get(`${BASE_URL}/merchant/dashboard/${id}`);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

export const talentResumeOrders = createAsyncThunk(
  "marchant/talentResumeOrders",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(`${BASE_URL}/merchant/resume-orders`, data);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

// For admin

export const talentCountSA = createAsyncThunk( 
  "marchant/adminTalentCount",
  async (_, { rejectWithValue }) => {
    try { 
      const response = await api.get(`${BASE_URL}/merchant/admin/dashboard`);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

export const getTalentsLsitAdmin = createAsyncThunk(
  "marchant/talentListAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/merchant/admin/talent-list`);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);

export const getMerchantList = createAsyncThunk(
  "marchant/merchantList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/merchant/list`);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);


export const merchantProfileVerifiedByAdmin = createAsyncThunk(
  "marchant/merchantProfileVerifiedByAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(`${BASE_URL}/merchant/profile-verified`, data);
      return response.data;
    } catch (error) { 
      throw rejectWithValue(error);
    }
  }
);