import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Radio,
  DatePicker, 
  Button,
  Card,
  Row,
  Col,
  message,
  Breadcrumb,
  notification
} from "antd"; 
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { getTalentInfo, newTalent, updateTalent } from "../../redux/actions/merchant.actions";
import moment from 'moment';

const { Option } = Select;
 

const EditTalentMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const record = location.state?.record; 

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); 
  const [nationalityArrayEdu, setNationalityArrayEdu] = useState([]);
  const [secQuali, setSecQuali] = useState([]);
  const [candidate, setCandidate] = useState(1);
  const [phonecode, setPhonecode] = useState([]);
 

  const onFinish = async (values) => {
    if (loading) return; 
    setLoading(true);
    
    const data = {
      ...values,
      phoneCode: values.phoneCode || 101,
      employmentSince: values.employmentSince || "",
      merchen_id: localStorage.getItem('userId'),
      id: record.id
    };
  
    try {
      const a = await dispatch(updateTalent(data));
      if (a.type == "marchant/updateTalent/fulfilled") {
        if (a?.payload?.status === true) {
          notification.success({
            message: "Talent Updated",
            description: a?.payload?.message, 
          });
        } else {
          notification.warning({
            message: "Talent Updated",
            description: a?.payload?.message,
          });
        }
      }
    } finally {
      setLoading(false); 
    }
  };
  
  const getTalentInfoDetails = async (record, form) => {
    setLoading(true); // Start loading
    try {
      await getapicall();

      const response = await dispatch(getTalentInfo(record));
      const talentData = response?.payload?.data;

      if (talentData) {
        form.setFieldsValue({
          email: talentData?.email || '',
          name: talentData?.name || '',
          qualification: talentData?.qualification || '',
          mobileNo: talentData?.mobile1 || '',
          phoneCode: talentData?.country_code || '',
          dob: talentData?.dob ? moment(talentData?.dob) : null,
          gender: talentData?.gender || '',
          candidateType: talentData?.is_experience || 1,
          employmentSince: talentData?.employmentSince ? moment(talentData?.employmentSince) : null,
          degreeName: talentData?.secondary_qualification || '',
        });


        // setNationalityArrayEdu(talentData?.qualification);
        handleDegree(talentData?.qualification); // Handle dependent dropdown
        setCandidate(talentData?.is_experience);
      }
    } catch (error) {
      console.error("Error fetching talent info:", error);
    } finally {
      setLoading(false); // End loading
    }
  };


  useEffect(() => {
    if (record?.id) {
      getTalentInfoDetails(record.id, form);
    }
  }, [record, form]);

 
  const getapicall = async () => {
    try {
      let countryArray = [];
      let phonecodeTArray = [];
      const response = await axios.get(`${BASE_URL}/master/countries`);
      for (let i = 0; i < response.data.data.length; i++) {
        let countryList = {
          value: response.data.data[i].id,
          label: response.data.data[i].name,
        };
        let phonecodeList = {
          value: response.data.data[i].id,
          label: "+" + response.data.data[i].phonecode,
        };
        countryArray.push(countryList);
        phonecodeTArray.push(phonecodeList);
      }
      setPhonecode(phonecodeTArray);
      let countryArray_edu = [];
      const response_edu = await axios.get(
        `${BASE_URL}/master/primary_qualification`
      );
      for (let i = 0; i < response_edu.data.data.length; i++) {
        let countryList = {
          value: response_edu.data.data[i].id,
          label: response_edu.data.data[i].english,
        };
        countryArray_edu.push(countryList);
      }
      setNationalityArrayEdu(countryArray_edu);
    } catch (e) {}
  };

  const handleDegree = async (e) => {
    let s_qualification = [];
    const response = await axios.get(
      `${BASE_URL}/master/secondary_qualification?id=${e}`
    );
    for (let i = 0; i < response.data.data.length; i++) {
      let stateList = {
        value: response.data.data[i].id,
        label: response.data.data[i].english,
      };
      s_qualification.push(stateList);
    }
    setSecQuali(s_qualification);
  };

  const handleCandidateChange = async (e) => {
    setCandidate(e.target.value);
  };

  return (
    <Fragment>
      <Breadcrumb
        style={{ margin: "16px 0", padding: "10px", fontWeight: 600 }}
      >
        <Breadcrumb.Item>Merchant / Edit Talent</Breadcrumb.Item>
      </Breadcrumb>
      <Card className="formCard">
        <Form form={form} onFinish={onFinish} labelCol={{ span: 12 }} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email ID"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input placeholder="Enter your valid email id" readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Full Name *"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
            </Col>
            <Col span={12} style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item name="phoneCode" label="Code*" style={{ width: "20%" }}>
                <Select defaultValue="+91" options={phonecode} />
              </Form.Item>
              <Form.Item
                name="mobileNo"
                label="Phone Number*"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number",
                    max: 10,
                  },
                ]}
                style={{ width: "76%" }}
              >
                <Input placeholder="Enter Phone number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="dob"
                label="Date of Birth"
                rules={[{ required: true, message: "Please select your date of birth" }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="gender" label="Gender" rules={[{ required: true, message: "Please select your gender" }]}>
                <Select placeholder="Gender">
                  <Select.Option value="1">Male</Select.Option>
                  <Select.Option value="2">Female</Select.Option>
                  <Select.Option value="3">Others</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="candidateType"
                label="Candidate Type"
                rules={[{ required: true, message: "Please select your candidate type" }]}
              >
                <Radio.Group onChange={handleCandidateChange}>
                  <Radio value="3">Intern</Radio>
                  <Radio value="1">Fresher</Radio>
                  <Radio value="2">Experience</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {candidate === 2 && (
              <Col span={24}>
                <Form.Item
                  name="employmentSince"
                  label="Employment Since"
                  rules={[{ required: true, message: "Please select your employment start date" }]}
                >
                  <DatePicker picker="month" />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                name="qualification"
                label="Qualification"
                rules={[{ required: true, message: "Please select your qualification" }]}
              >
                <Select
                  id="primary_qualification"
                  showSearch
                  placeholder="Select primary qualification"
                  onChange={handleDegree}
                  options={nationalityArrayEdu}
                  autoComplete="off"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="degreeName"
                label="Degree Name"
                rules={[{ required: true, message: "Please select your degree" }]}
              >
                <Select
                  showSearch
                  placeholder="Select Degree"
                  options={secQuali}
                  autoComplete="off"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Fragment>
  );
};

export default EditTalentMain;
